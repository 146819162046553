import React from 'react';
import _isEmpty from 'lodash.isempty';
import key from 'weak-key';

import LinkBlock from './LinkBlock';
import { generateSlug } from '../../utils/slug';
import { InnerHtml } from '../InnerHtml/inner-html';
import { SearchIndexOff } from '../SearchIndex/SearchIndexOff';

interface RelatedLinksProps {
  title: string | null;
  anchor: string | null;
  categories: any[] | null;
}

function RelatedLinks({
  title = null,
  anchor = null,
  categories = null,
}: Readonly<RelatedLinksProps>) {
  if (_isEmpty(title) || _isEmpty(categories)) {
    return null;
  }

  return (
    <SearchIndexOff>
      <section className="c-related-links" id={generateSlug(anchor || title)}>
        <div className="grid-container grid-x">
          <div className="small-12 medium-12 large-3 cell">
            <InnerHtml as="div" content={title} />
          </div>
          <div className="grid-container grid-x list-block-column small-12 medium-12 large-9">
            {categories.map((items) => (
              <LinkBlock linkBlock={items} key={key(items)} />
            ))}
          </div>
        </div>
      </section>
    </SearchIndexOff>
  );
}

export default RelatedLinks;
