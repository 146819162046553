import React from 'react';
import isEmpty from 'lodash.isempty';
import key from 'weak-key';

// Utils
import { renderLinks } from '../../utils/links';

interface LinkBlockProps {
  linkBlock: any;
}

function LinkBlock({ linkBlock }: Readonly<LinkBlockProps>) {
  const { links, title } = linkBlock;

  if (isEmpty(links)) {
    return null;
  }

  const listItems = links.map((itemLink) => {
    const newItemLink = {
      ...itemLink,
      text: itemLink.text.slice(0, 90),
    };
    return <li key={key(itemLink)}>{renderLinks(newItemLink)}</li>;
  });

  return (
    <div className="c-related-links__block">
      {title && <h4 className="h6">{title}</h4>}
      <nav>
        <ul className="link-list">{listItems}</ul>
      </nav>
    </div>
  );
}

export default LinkBlock;
