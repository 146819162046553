// types
import type { SliderItemProps } from 'components/ContentElements/Slider';
import type { ContentElementPayload } from 'components/ContentElements/content-elements-payload';

// components
import MetaData from '../MetaData';
import Footer from 'components/Footer/Footer';
import { ContentArea } from 'components/ContentArea/ContentArea';
import { Slider } from 'components/ContentElements/Slider/Slider';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import RelatedLinks from 'components/RelatedLinks/RelatedLinks';
import SocialShare from 'components/SocialShare/SocialShare';
import TableOfContents from 'components/TableOfContents/TableOfContents';

// utils
import { TOC_DATA_CONTENTPAGE } from 'components/TableOfContents/tocDataAdapter';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { isEmpty } from 'utils/is-empty';

type DefaultProps = Omit<RootObject, 'page' | 'contentAreas'> & {
  page: RootObject['page'] & {
    heroTileItems: SliderItemProps[];
  };
  contentAreas: {
    content: ContentElementPayload[];
  };
};

export function Default({
  breadcrumbs,
  relatedLinks,
  page: { subHeadline, headline, heroTileItems, socialSharing, showAnchor },
  contentAreas,
  metaData,
}: Readonly<DefaultProps>) {
  const hasLocatorSection =
    !isEmpty(contentAreas.content) && contentAreas.content[0].type === 'locator';

  return (
    <>
      <MetaData {...metaData} />
      <main>
        {!isEmpty(heroTileItems) && (
          <Slider
            items={heroTileItems}
            sliderType="above-breadcrumb"
            sliderContext="above-breadcrumb"
          />
        )}
        <Breadcrumb items={breadcrumbs} />
        <PageHeadline
          Format={TitleFormats.h1}
          title={headline}
          subtitle={subHeadline}
          pageHeadline
        />

        {showAnchor && !hasLocatorSection && (
          <TableOfContents
            type={TOC_DATA_CONTENTPAGE}
            headline={headline}
            content={contentAreas.content}
          />
        )}

        <ContentArea content={contentAreas.content} contentType="content" />
        {socialSharing === 'true' ? (
          <section className="grid-container">
            <SocialShare
              contentType="page"
              className="content-page__social-share"
              metaData={metaData}
              types={['facebook', 'twitter', 'pinterest', 'service-mail']}
            />
          </section>
        ) : null}
        <RelatedLinks
          title={relatedLinks.title}
          anchor={relatedLinks.anchor}
          categories={relatedLinks.categories}
        />
      </main>
      <Footer />
    </>
  );
}
